import React from "react";
import { Checkbox as AntdCheckbox } from "antd";
import classNames from "classnames";

// interfaces
import { CheckboxProps } from "./Checkbox.interface";

// helpers
import LabelErrorProvider from "@/components/LabelErrorProvider";

// assets
import styles from "../../assets/styles/shared/checkbox.module.scss";

function Checkbox(props: CheckboxProps) {
  const {
    children,
    wrapperClassName,
    className = "",
    size = "regular",
    error,
    label,
    disabled,
    ...rest
  } = props;

  const errorProviderClassName = classNames(
    "locl-checkbox-wrapper",
    styles["locl-checkbox-wrapper"],
    styles[`locl-checkbox-wrapper_size-${size}`],
    wrapperClassName
  );

  const checkboxClassName = classNames(
    "locl-checkbox",
    styles["locl-checkbox"],
    className,
    styles[`locl-checkbox_size-${size}`]
  );

  return (
    <LabelErrorProvider
      label={label}
      error={error}
      disabled={disabled}
      className={errorProviderClassName}
    >
      <AntdCheckbox disabled={disabled} {...rest} className={checkboxClassName}>
        {children}
      </AntdCheckbox>
    </LabelErrorProvider>
  );
}

export default Checkbox;
