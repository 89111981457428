import { AxiosRequestConfig } from "axios";
import { getCookie, setCookie, deleteCookie } from "cookies-next";

// interfaces
import { PutRegisterReactivateData, PostTokenInterface, PostTokenResponseInterface, PostCreateSubscriptionProps } from "./account.interface";
import { UserProfileInterface } from "@/types/api/UserProfileInterface.interface";
import { AxiosResponseError } from "@/types/api/AxiosResponseError.interface";


// helpers
import axios from "../axios";
import API_ROUTES from "../routes.constant";
import { queryClient } from "../query/reactQuery";

export default {
  putRegisterReactivate(data: PutRegisterReactivateData) {
    try {
      return axios.put(API_ROUTES.account.registerReactivate, data);
    } catch (err: unknown) {
      const error = err as AxiosResponseError;

      if (error && error.response.data) {
        throw error.response.data;
      }
  
      throw error;
    }
  },

  async postTokenOps(data: PostTokenInterface, config?: AxiosRequestConfig) {
    const response = await axios.post<PostTokenResponseInterface>(API_ROUTES.ops.login, data, config);

    if (response.data) {
      setCookie("accessToken", response.data.access, { path: "/", maxAge: 60 * 60 * 8760, });
      setCookie("refreshToken", response.data.refresh, { path: "/", maxAge: 60 * 60 * 8760, });
    }

    await queryClient.setQueryData([API_ROUTES.account.isAuthenticated, {}], () => ({
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
    }));

    return response;
  },

  async postToken(data: PostTokenInterface, isRememberMe?: boolean, config?: AxiosRequestConfig) {
    const response = await axios.post<PostTokenResponseInterface>(API_ROUTES.account.token, data, config);

    if (response.data) {
      setCookie("accessToken", response.data.access, {
        path: "/",
        maxAge: 60 * 60 * 8760,
      });
      // eslint-disable-next-line no-undefined
      setCookie("refreshToken", isRememberMe ? response.data.refresh : undefined, {
        path: "/",
        maxAge: 60 * 60 * 8760,
      });
    }

    await queryClient.setQueryData([API_ROUTES.account.isAuthenticated, {}], () => ({
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
    }));

    return response;
  },

  async getProfile(config?: AxiosRequestConfig) {
    const resp = await axios.get<UserProfileInterface>(API_ROUTES.account.profileMe, config);

    return resp.data;
  },

  checkUserActive({ token, type }: { token: string, type: "landlord" | "hirer" }) {
    return axios.get(API_ROUTES.account.checkUserActive, {
      params: {
        token,
        type
      }
    });
  },

  async logOut(redirectOrl?: string) {
    let refreshToken = null;

    refreshToken = getCookie("refreshToken");

    return axios
      .post(API_ROUTES.account.logOut, { refresh: refreshToken })
      .finally(() => {
        deleteCookie("accessToken");
        deleteCookie("refreshToken");

        queryClient.removeQueries({ queryKey: [API_ROUTES.account.isAuthenticated, {}] });

        window.location.href = redirectOrl || "/login";
      });
  },

  postCreateSubscription(props: PostCreateSubscriptionProps) {
    return axios.post(API_ROUTES.account.marketingPopup, { ...props });
  },

  postConfirmEmailChange(data: { token: string }) {
    return axios.post(API_ROUTES.account.confirmEmailChange, data);
  },

  getAccountProfileVerification(data: { token: string }) {
    return axios.get<UserProfileInterface>(API_ROUTES.account.profileVerification, {
      params: data,
    });
  }
}
