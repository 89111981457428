/* eslint-disable max-len */
import React from "react";

// components
import Link from "@/components/Link";

const wording = {
  "LOCL": {
    header: {
      navigation: {
        desktop: {
          goSearch: "New Search",
          myCampaigns: "Campaigns",
          myBookings: "Bookings",
          myMessages: "Messages",
        },
        mobile: {
          goSearch: "New Search",
          myCampaigns: "Campaigns",
          myBookings: "Bookings",
          myMessages: "Messages",
          myAccount: "Account",
          mySettings: "Settings"
        },
      }
    },
    auth: {
      login: {
        title: "Log in",
        subtitle: null,
      },
      loginModal: { title: "Log in" },
      signUp: {
        title: "Sign up",
        subtitle: (<>You’re just 30 secs away from gaining free, unlimited access to 1,000s of experiential <br/>
        spaces to search, plan and book</>),
        termsOfUse: (<>I accept the{" "}
          <Link
            href="/terms-of-use"
            rel="noreferrer"
            target="_blank"
            className="locl-wording-auth-sign-up-link"
          >
          Terms of Use
          </Link>{" "}
        (you must agree to sign up)</>),
        marketing: "Send me news and offers about location:live's services, events and more."
      },
      setPassword: { title: "Create your password", },
    },
    lockoutModal: {
      soft: {
        title: "For free, unlimited access to our experiential space marketplace, please sign up or login",
        subtitle: "In just a few clicks, you’ll have the freedom to search over 2,000 spaces in the UK and Europe, as well as access to our Campaign Manager for planning, managing and booking your space directly with the landlord.",
      },
    },
    legal: {
      aside: {
        bookingTerms: "Booking Terms",
        privacyPolicy: "Privacy Policy",
        cookieNotice: "Cookie Notice",
        termsOfUse: "Terms of Use",
      },
      termsOfUse: {
        introduction: {
          title: "Introduction",
          description: (<>
            <b>lo:live</b> is an online platform for buying and selling space for promotional,
            brand and other experiential activity (the “<b>Platform</b>”). The Platform is operated by Location
            Live Limited (“<b>Location Live</b>” and “<b>we</b>”). These terms of use (the “<b>Terms of Use</b>”)
            apply to anyone accessing or using the Platform (“<b>you</b>”). You can access the Platform {"at "}
            <Link href="/lolive" className="locl-legal-page__description_link" rel="noreferrer" target="_blank">https://locationlive.com/lolive</Link>.
          </>)
        },
        acceptance: {
          title: "Accepting these terms of use",
          description: (<>
            <p>
              By accessing or using any part of the Platform you accept these Terms of Use on behalf of yourself
              and your business.
            </p>
            <p>
              If you operate a business as a sole trader, references in these Terms of Use to your business are
              references to you acting as a sole trader.
            </p>
            <p>
              If you represent an organisation that does not operate as a business (for example, a charitable
              organisation), references in these Terms of Use to your business are references to such other
              organisation.
            </p>
          </>),
        },
        registeringAsClient: {
          title: "Registering as a client",
          description: (<>
            <p>
              You do not need an account to access the Platform but you need an account to access and use many
              of the services on the Platform. You can sign up to the Platform and create an account for your
              business at <Link className="locl-legal-page__description_link" href="/registration">https://locationlive.com/registration</Link>.
            </p>
            <p>
              By signing up, you are registering your business as our client and engaging us to search for space
              for promotional, brand and other experiential activity on behalf of your business. You represent to
              us that you have authority to
              register your business as our client.
            </p>
          </>),
        },
        licenceForClients: {
          title: "Licence for clients to use platform content",
          description: (<>
            <p>
              If you are registered as a client,  we grant your business a licence to export content made available
              on the Platform for the sole purpose of producing materials for specific promotional campaigns for which you
              intend to buy space on the Platform. When exporting content for any specific campaign, you agree to only
              export the content your business requires for that campaign, as you consider appropriate for the identified
              geographic scope and date range of that campaign. You agree not to export content for general use or to
              re-use content exported for a specific campaign.
            </p>
            <p>
              If you are registered as a client or a location provider, you agree not to export, download, reproduce,
              duplicate, copy or re-sell any content available on the Platform other than in accordance with these
              Terms of Use and any applicable law.
            </p>
          </>)
        },
        antiCircumvention: {
          title: "Anti-circumvention",
          description: (<>
            <p>
            If you are registered as a client, you acknowledge that the Platform is only intended for your use if your
            business wants to buy space and you intend to book it on the Platform. You agree on behalf of yourself
            and your business that you or your business will not use the Platform to search for spaces and then
            circumvent the Platform by attempting to book outside the Platform.
            </p>
            <p>
            If you are registered as a location provider, you agree not to circumvent the Platform by soliciting
            or encouraging clients on our Platform to make bookings outside the Platform.
            </p>
          </>),
        },
        acceptingOtherTerms: {
          title: "Accepting other terms",
          description: (<>
            <p>
            You accept our Privacy Policy on behalf of yourself and your business, available at {" "}
              <Link className="locl-legal-page__description_link" href="/privacy">https://locationlive.com/privacy</Link>.
            </p>
            <p>
            You acknowledge our Cookie Notice on behalf of yourself and your business, available at {" "}
              <Link className="locl-legal-page__description_link" href="/cookies">https://locationlive.com/cookies</Link>.
            </p>
          </>)
        },
        lastUpdated: {
          title: "Last update to these terms of use",
          description: "These Terms of Use were last updated on 29 January 2024."
        },
      },
    },
  },
  "WERI": {
    header: {
      navigation: {
        desktop: {
          goSearch: "Search",
          myCampaigns: "Campaigns",
          myBookings: "Bookings",
          myMessages: "Messages",
        },
        mobile: {
          goSearch: "Start new search",
          myCampaigns: "Campaigns",
          myBookings: "Bookings",
          myMessages: "Messages",
          myAccount: "Account",
          mySettings: "Settings"
        },
      }
    },
    auth: {
      login: {
        title: "Log in with your lo:live account details",
        subtitle: "Alternatively, if you do not have a lo:live account you can sign up via the link",
      },
      loginModal: { title: "Log in with your lo:live account details" },
      signUp: {
        title: "Sign up to lo:live",
        subtitle: (<>It's easy to search for and browse locations. To use our more advanced features you will<br/>
        need a lo:live account.</>),
        termsOfUse: (<>I accept the{" "}
          <Link
            href="/terms-of-use"
            rel="noreferrer"
            target="_blank"
            className={"locl-wording-auth-sign-up-link"}
          >
            Terms of Use
          </Link>{" "}
        </>),
        marketing: "Yes! Send me news and offers about Location Live services, events and more."
      },
      setPassword: { title: "Create your password", },
    },
    lockoutModal: {
      soft: {
        title: "Log in with your lo:live account details",
        subtitle: "To use our more advanced features you will need a lo:live account. If you do not have an account you can sign up below.",
      },
    },
    legal: {
      aside: {
        bookingTerms: "Booking Terms",
        privacyPolicy: "Privacy Policy",
        cookieNotice: "Cookie Notice",
        termsOfUse: "Terms of Use",
      },
      termsOfUse: {
        introduction: {
          title: "Introduction",
          description: (<>
            <p>
              <b>lo:live</b> is an online platform for buying and selling space for promotional, brand
              and other experiential activity (the <b>“Platform”</b>). The Platform is operated by Location Live Limited
              (<b>“Location Live”</b> and <b>“we”</b>). These terms of use (the <b>“Terms of Use”</b>) apply to anyone
              accessing or using the Platform as a client (<b>“you”</b>).
            </p>
            <p>
              You can access the Platform’s main marketplace at <a className="locl-legal-page__description_link" href="https://locationlive.com/lolive" rel="noreferrer" target="_blank">https://locationlive.com/lolive</a> or from links contained on our website at <a className="locl-legal-page__description_link" href="https://locationlive.co.uk" rel="noreferrer" target="_blank">https://locationlive.co.uk</a>.
            </p>
            <p>
              You are currently accessing the Platform in a dedicated portal for Westfield Rise available at <a className="locl-legal-page__description_link" href="https://westfieldrise.locationlive.com" rel="noreferrer" target="_blank">https://westfieldrise.locationlive.com.</a> You can also access this portal from links contained on Westfield Rise’s website at <a className="locl-legal-page__description_link" href="https://westfieldrise.com" rel="noreferrer" target="_blank">https://westfieldrise.com.</a>
            </p>
          </>)
        },
        acceptance: {
          title: "Accepting these terms of use",
          description: (<>
            <p>
              By accessing or using any part of the Platform you accept these Terms of Use on behalf of yourself
              and your business.
            </p>
            <p>
              If you operate a business as a sole trader, references in these Terms of Use to your business are
              references to you acting as a sole trader.
            </p>
            <p>
              If you represent an organisation that does not operate as a business (for example, a charitable
              organisation), references in these Terms of Use to your business are references to such other
              organisation.
            </p>
          </>),
        },
        registeringAsClient: {
          title: "Registering as a client",
          description: (<>
            <p>
            You do not need an account to access the Platform but you need an account to access and use many of the
            services on the Platform. You can sign up to the Platform and create an account for your business
            at <a className="locl-legal-page__description_link" href="https://locationlive.com/registration">https://locationlive.com/registration</a> (our main marketplace) or at <Link className="locl-legal-page__description_link" href="/registration">https://westfieldrise.locationlive.com/registration</Link> (the Westfield Rise portal). Once you have registered, your login will work on both the main marketplace and the Westfield Rise portal.
            </p>
            <p>
            By signing up, you are registering your business as our client and engaging us to search for space
            for promotional, brand and other experiential activity on behalf of your business. You represent to
            us that you have authority to
            register your business as our client.
            </p>
          </>),
        },
        licenceForClients: {
          title: "Licence for clients to use platform content",
          description: (<>
            <p>
            We grant your business a licence to export content made
            available on the Platform for the sole purpose of producing materials for specific promotional campaigns
            for which you intend to buy space on the Platform. When exporting content for any specific campaign,
            you agree to only export the content your business requires for that campaign, as you consider
            appropriate for the identified geographic scope and date range of that campaign. You agree not to
            export content for general use or to re-use content exported for a specific campaign.
            </p>
            <p>
            You agree not to export, download, reproduce, duplicate, copy or re-sell any content available on
            the Platform other than in accordance with these Terms of Use and any applicable law.
            </p>
          </>)
        },
        antiCircumvention: {
          title: "Anti-circumvention",
          description: `You acknowledge that the Platform is only intended for your use if your business wants to buy space and you intend to book it on the Platform. 
            You agree on behalf of yourself and your business that you or your business will not use the Platform to search for spaces and then circumvent the 
            Platform by attempting to book outside the Platform.`,
        },
        acceptingOtherTerms: {
          title: "Accepting other terms",
          description: (<>
            You accept our Privacy Policy on behalf of yourself and your business, available at {" "}
            <a className="locl-legal-page__description_link" href="https://locationlive.com/privacy">https://locationlive.com/privacy</a> or <Link className="locl-legal-page__description_link" href="/privacy">https://westfieldrise.locationlive.com/privacy</Link>.
            <br/>You acknowledge our Cookie Notice on behalf of yourself and your business, available at {" "}
            <a className="locl-legal-page__description_link" href="https://locationlive.com/cookies">https://locationlive.com/cookies</a>  or <Link className="locl-legal-page__description_link" href="/cookies">https://westfieldrise.locationlive.com/cookies</Link>.
          </>)
        },
        lastUpdated: {
          title: "Last update to these terms of use",
          description: "These Terms of Use were last updated on 29 January 2024."
        },
      },
    },
  }
};

export default wording[process.env.NEXT_PUBLIC_APP_WL_CODE as "LOCL" | "WERI"];
