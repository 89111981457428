import React from "react";
import { Checkbox as AntdCheckbox } from "antd";
import classNames from "classnames";

// interfaces
import { CheckboxGroupProps } from "./Checkbox.interface";

// components
import LabelErrorProvider from "../../components/LabelErrorProvider";

// assets
import styles from "../../assets/styles/shared/checkbox.module.scss";

function CheckboxGroup(props: CheckboxGroupProps) {
  const { className, fullWidth, label, error, ...rest } = props;

  const checkboxGroupClassName = classNames(styles["locl-checkbox-group"], className, {
    [styles["locl-checkbox-group_full-width"]]: fullWidth
  });

  return (
    <LabelErrorProvider label={label} error={error}>
      <AntdCheckbox.Group className={checkboxGroupClassName} {...rest} />
    </LabelErrorProvider>
  );
}

export default CheckboxGroup;
