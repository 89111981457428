"use client";

import { Button as AntdButton } from "antd";
import classNames from "classnames";

// components
import Link from "@/components/Link";

// interfaces
import { ButtonProps } from "./Button.interface";

// assets
import styles from "@/assets/styles/shared/button.module.scss";

function Button(props: ButtonProps) {
  const {
    children,
    fullWidth,
    className,
    href,
    to,
    type = "primary",
    size = "regular",
    danger,
    success,
    icon,
    iconRight,
    disabled,
    ghost,
    linkClassName,
    linkStyle,
    linkProps = {},
    ...rest
  } = props;

  const btnClassName = classNames(
    className,
    "locl-button",
    styles["locl-button"],
    styles[`locl-button_type-${type}`],
    styles[`locl-button_size-${size}`],
    {
      [styles["locl-button_icon"]]: !children && !!icon,
      [styles["locl-button_danger"]]: danger,
      [styles["locl-button_success"]]: success,
      [styles["locl-button_ghost"]]: ghost
    }
  );

  const linkClassNameToUse = classNames([styles["locl-button__link"]], linkClassName, {
    [styles["locl-button__link_full-width"]]: fullWidth
  });

  if (href) {
    return (
      <a
        href={href}
        onClick={(e) => !!disabled && e.preventDefault()}
        data-testid="locl-button-link"
        className={linkClassNameToUse}
        style={linkStyle}
        { ...linkProps }
      >
        <>
          <AntdButton
            {...rest}
            disabled={disabled}
            icon={icon}
            className={btnClassName}
            block={fullWidth}
          >
            {children}
            {iconRight}
          </AntdButton>
        </>
      </a>
    );
  }

  if (to) {
    return (
      <Link
        href={to}
        onClick={(e) => !!disabled && e.preventDefault()}
        data-testid="locl-button-link-react"
        className={linkClassNameToUse}
        style={linkStyle}
      >
        <AntdButton
          {...rest}
          disabled={disabled}
          icon={icon}
          className={btnClassName}
          block={fullWidth}
        >
          {children}
          {iconRight}
        </AntdButton>
      </Link>
    );
  }

  return (
    <AntdButton
      {...rest}
      disabled={disabled}
      icon={icon}
      block={fullWidth}
      className={btnClassName}
    >
      {children}
      {iconRight}
    </AntdButton>
  );
}

export default Button;
