import { getCookie } from "cookies-next";

// interfaces
import { QueryArgsInterface, IUseMutation } from "@/types/api/queryArgs.interface";
import { UserProfileInterface, IMember } from "@/types/api/UserProfileInterface.interface";
import { IAccessLevelList, IInvitation, IRegisterInviteSetPassword, IUpdateUser } from "@/types/api/account.interface";
import { IUseQueryResult } from "@/types/api/IUseQueryResult.interface";
import { IPaginatedResponse } from "@/types/api/PaginatedResponse";
import { AccessLevelsType } from "@/types/api/AccessLevels.interface";

// helpers
import { useFetch, usePost, usePut, usePatch, useWebsocket } from "./reactQuery";
import { pathToUrl } from "./reactQuery/pathToUrl";
import API_ROUTES, { WEBSOCKET_ROUTES } from "@/api/routes.constant";
import accountApi from "@/api/account";


const account = {
  useAccountUsers: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch(API_ROUTES.account.accountUsers, params, config) as IUseQueryResult<
      IPaginatedResponse<UserProfileInterface>
    >;
  },
  useProfileMe: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<UserProfileInterface>(API_ROUTES.account.profileMe, params, config);
  },
  useManagersAndStaff: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<{
      company: { managers: IMember[]; staff: IMember[], admins: IMember[] },
    }>(API_ROUTES.account.managersAndStaff, params, config);
  },
  useUpdateProfile: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePatch(API_ROUTES.account.profileMe, params, config);
  },

  useRegisterHirer: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.registerHirer, params || {}, config);
  },
  useRegisterLandlord: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.registerLandlord, params || {}, config);
  },
  useResetPasswordComplete: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePut(API_ROUTES.account.resetPasswordComplete, params || {}, config);
  },
  useResetPassword: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePut(API_ROUTES.account.resetPassword, params || {}, config);
  },
  useRegisterSetPassword: (args?: IRegisterInviteSetPassword) => {
    const { params, config, userType } = args || {};

    return usePut(
      userType ? pathToUrl(API_ROUTES.account.registerSetPassword, { userType }) : "",
      params || {},
      config
    );
  },
  useInviteSetPassword: (args?: IRegisterInviteSetPassword) => {
    const { params, config, userType } = args || {};

    return usePut(
      userType ? pathToUrl(API_ROUTES.account.inviteSetPassword, { userType }) : "",
      params || {},
      config
    );
  },
  useDeleteAccount: (args?: IRegisterInviteSetPassword) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.accountDelete, params || {}, config);
  },
  useDeleteMemberAccount: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.accountDeleteMember, params || {}, config);
  },
  useUpdatePassword: (args?: IRegisterInviteSetPassword) => {
    const { params, config } = args || {};

    return usePut(API_ROUTES.account.passwordChange, params, config);
  },
  useUpdatePreferences: (args: IUseMutation) => {
    const { params, config } = args || {};

    return usePut(API_ROUTES.account.profilePreferences, params, config);
  },
  useUpdateProfileSettings: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePut(API_ROUTES.account.accountProfileSettings, params, config);
  },

  useInvitation: (args?: IInvitation) => {
    const { params, config, roleType } = args || {};

    return usePost(
      roleType ? pathToUrl(API_ROUTES.account.invitationUrl, { type: roleType }) : "", params, config);
  },
  useUpdateUser: (args?: IUpdateUser) => {
    const { params, config, userId } = args || {};

    return usePatch(userId ? pathToUrl(API_ROUTES.account.users, { userId }) : null, params, config);
  },
  useAccessLevelList: (args?: IAccessLevelList) => {
    const { params, config, roleType } = args || {};

    return useFetch<AccessLevelsType[]>(
      roleType ? pathToUrl(API_ROUTES.account.accessLevel, { type: roleType }) : null,
      params,
      config
    )
  },
  useDeleteMember: (args: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.accountDeleteMember, params, config);
  },
  useIsAuthenticated: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<{ accessToken: string, refreshToken: string }>(API_ROUTES.account.isAuthenticated, params, {
      initialData: {
        accessToken: getCookie("accessToken"),
        refreshToken: getCookie("refreshToken"),
      },
      queryFn: async () => ({
        accessToken: getCookie("accessToken"),
        refreshToken: getCookie("refreshToken"),
      }),
      ...config,
    })
  },
  useChangeEmail: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.changeEmail, params, config);
  },
  useConfirmProfileVerification: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.confirmProfileVerification, params, config);
  },
  useResendActivationEmail: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.account.resendActivationEmail, params, config);
  },
  
  useCheckUserDeactivated() {
    const { data } = this.useIsAuthenticated();

    const socketConnection = useWebsocket({
      socketUrl: WEBSOCKET_ROUTES.checkUserDeactivated,
      params: { token: data?.accessToken },
      config: { enabled: !!data?.accessToken },
      callbacks: {
        message: (event) => {
          const eventData = JSON.parse(event.data);

          if (eventData && eventData.is_deactivated_by_admin) {
            accountApi.logOut();
          }
        }
      },
    });

    return socketConnection;
  }
};

export default account;
